import request from '@/../utils/request'
const api_name = '/IndustryInfoController'

export default {
    //添加账号绑定的行业
    pageIndustryList(current, limit, industryName) {
        return request({
            url: `${api_name}/pageIndustryList`,
            method: 'post',
            data: {
                current: current,
                limit: limit,
                industryName: industryName,
                
            }
        })
    },
    //添加公司绑定行业
    addIndustry(industryName) {
        return request({
            url: `${api_name}/addIndustry`,
            method: 'post',
            data: {
                industryName: industryName
            }
        })
    },

    //删除行业
    removeIndustry(id) {
        return request({
            url: `${api_name}/removeIndustry/${id}`,
            method: 'get',
            
        })
    },

    //查询行业
    getIndustryList() {
        return request({
            url: `${api_name}/getIndustryList`,
            method: 'get',
            
        })
    },

  

    
}