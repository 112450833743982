<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="行业系统管理"> </el-page-header>
      </el-col>
    </el-row> -->
    <el-row>
      <el-input v-model="industryName" placeholder="请输入行业名称" style="width: 20%;" clearable></el-input>
      <el-button type="primary" @click="searchInfo" style="margin:0 10px">查询</el-button>
      <el-button icon="el-icon-plus" type="success" @click="addIndustry = true" class="add" style="width:5%">添加
      </el-button>
    </el-row>
    <el-table :data="industryList" style="width: 100%" :height="700">
      <el-table-column type='index' label="序号" width="80" align="center">
      </el-table-column>
      <el-table-column prop="industryName" label="行业名称" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button @click="deletInstury(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 添加信息弹框 -->
    <el-dialog title="行业信息" :visible.sync="addIndustry" width="30%" :close-on-click-modal="false">
      <el-form>
        <el-form-item label="行业名称">
          <el-input v-model="addIndustryName" placeholder="请填写行业名称" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addIndustry = false">取 消</el-button>
        <el-button type="primary" @click="appendInfo">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination :background="true" @current-change="pagination" :current-page.sync="current" :page-size="limit" layout="total, prev, pager, next , jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>


import indestryAdministration from "../../api/manageApi/indestryAdministration";

export default {
  data() {
    return {
      addIndustry: false,   //添加弹框，默认隐藏
      industryName: '',    // 搜索行业名称
      addIndustryName: '',   // 添加行业名称
      total: 0,   //总数
      current: 1,  //当前页
      limit: 10,       //每页条数     
      industryList: []  //定义行业集合



    };
  },
  created() {
    this.pageIndustryList();


  },

  methods: {
    //查询方法
    searchInfo() {
      this.pageIndustryList();

    },
    //删除行业信息
    deletInstury(info) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        indestryAdministration.removeIndustry(info.id).then((res) => {
          console.log(res);
          this.pageIndustryList();
          if (res.data.message == "删除成功") {
            this.$message.success(res.data.message)
          }
        });
      });
    },


    //返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    //分页查询
    pageIndustryList() {
      indestryAdministration.pageIndustryList(this.current, this.limit, this.industryName).then((res) => {
        console.log(res);
        this.industryList = res.data.data.list;
        this.total = res.data.data.total;
      })
    },

    //添加行业信息
    appendInfo() {
      // 添加行业
      if (this.addIndustryName != "") {
        indestryAdministration.addIndustry(this.addIndustryName).then(res => {
          console.log(res);
          res.data.message == "添加成功"
          this.$message({
            type: "success",
            message: "添加成功"
          })
          this.addIndustry = false;
          this.addIndustryName == "";

        }).then(() => {
          this.pageIndustryList();
        })
      } else {
        this.$message.error("输入内容不能为空");
      }

    },

    // 分页
    pagination(value) {
      this.current = value
      this.pageIndustryList()
    },



  }
}
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 0 5px;
}
.add {
  margin: 10px 0;
}
</style>